import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';
import './body.css';

import ds from './images/ds.jpg'
import python from './images/python.jpg'
import web from './images/web.jpg'

function Body() {
  return (
    <div className="body">
        <div className='bodypadding'>
      <div
        className="banner"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/ban.jpg)`, 
        }}
      >
      </div>
      <div>
        
      </div>

      <div className="content-container">
        <h1 className="text-center" id='services'>Services</h1>
        <p className="text-center">
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </p>
      </div>
      <div className="card-grid">
          <div className="card">
            <div className='outSerial-number'><div className="serial-number">1</div></div>
            <div className="card-header">
              <h2>Research and Development</h2>
            </div>
            <div className="card-content">
              <p>Different types of research and development activities are taking place at our organization to ensure that the students can create a progressive approach to their education system.</p>
            </div>
          </div>
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">2</div></div>
            <div className="card-header">
              <h2>Data Sceince</h2>
            </div>
            <div className="card-content">
              <p>At ARC, we create a lot of scope for data science projects to ensure that the students benefit from various data science activities that will create landmarks in society.</p>
            </div>
          </div>
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">3</div></div>
            <div className="card-header">
              <h2>Statistical Analysis</h2>
            </div>
            <div className="card-content">
              <p>In the present market, the industry needs a lot of statistical analysis for which our organization provides well-established and educated experts to conduct various types of statistical analysis for their data. Here we are interacting with different sectors of industry and ensuring that that data has been involved to make future decisions to excel in the market.</p>
            </div>
          </div>
        </div>
        <div className="card-grid">
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">4</div></div>
            <div className="card-header">
              <h2>Web Technologies</h2>
            </div>
            <div className="card-content">
              <p>We use different web technologies to present our designs and develop various research activities to present the work at ARC.</p>
            </div>
          </div>
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">5</div></div>
            <div className="card-header">
              <h2>Mobile App Development</h2>
            </div>
            <div className="card-content">
              <p>We assist students with developing their to own mobile apps by volunteering to help them with both the development process.</p>
            </div>
          </div>
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">6</div></div>
            <div className="card-header">
              <h2>Dissertation Support</h2>
            </div>
            <div className="card-content">
              <p>We support the students in writing their dissertations and also offer 0% Plagiarism documentation for the students.</p>
            </div>
          </div>
        </div>
        <div className="card-grid">
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">7</div></div>
            <div className="card-header">
              <h2>Research Paper Guaidance</h2>
            </div>
            <div className="card-content">
              <p>Students who need assistance writing research papers can get it from ARC, which will also assist them in maintaining a variety of references in their papers.</p>
            </div>
          </div>
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">8</div></div>
            <div className="card-header">
              <h2>Python & R/ Power BI</h2>
            </div>
            <div className="card-content">
              <p>Various languages (Python, Power BI) that supports big data are being consisdered at the time of training students. </p>
            </div>
          </div>
          <div className="card">
          <div className='outSerial-number'><div className="serial-number">9</div></div>
            <div className="card-header">
              <h2>Industrial Training</h2>
            </div>
            <div className="card-content" id='about'>
              <p>We provide training for various courses that suites the requirements of the current industry.</p>
            </div>
          </div>
        </div>

        <div className="about" id='about'>
      <div className="contact-card">
        <h2>About Us</h2>
        <p>ARC Training Centre is a versatile hub specializing in comprehensive training programs. From cutting-edge research and development to hands-on guidance in data science, statistical analysis, web technologies, and mobile app development, ARC equips learners with practical skills. They offer expert assistance in research paper composition, dissertation support, and research guidance. With a focus on Python, R, and Power BI, ARC empowers participants with vital programming and data visualization tools. Additionally, they provide immersive industrial training, fostering real-world readiness. ARC Training Centre stands as a holistic platform, fostering proficiency in diverse domains essential for thriving in today's technology-driven landscape.</p>
      </div>
    </div>
    <div className="image-slides">
      <Carousel showThumbs={false} autoPlay={true} interval={1000}> 
          <div>
            <img src={ds} alt="Slide 1" />
          </div>
          <div>
            <img src={python} alt="Slide 2" />
          </div>
          <div>
            <img src={web} alt="Slide 2" />
          </div>
          {/* Add more slides */}
        </Carousel>
      </div>
        <div className="content-container">
        <h1 className="text-center" id='contact'>Contact Us</h1>
      </div>
      </div>
      <div className="contact-section">
        <p className="contact-info">support@studentservice.info</p>
        <p className="contact-info">Ph.no : 8019192444</p>
        <p className="contact-info">Branch Office
H. No.: 1-8-32/39 , Bapu Bagh, Ram Gopalpet Police Station, Secunderabad - 500 003, Telangana, India.</p>
      </div>
      
      <div className='bottom-line'>
        <div className="footer">
            <p className="copyright">© Copyright 2023. All Rights Reserved ARC</p>
        </div>
      </div>
      
    </div>
  );
}

export default Body;
