import React, { useState } from 'react';
import './header.css';
import './body.js';
import './About.js';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img
          src={process.env.PUBLIC_URL + '/logo.jpg'}
          alt="My App Logo"
          className="logo-image"
        />
      </div>

      <nav className="navbar">
        <div className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#about">About</a></li>
            <li><a id='last-child' href="#contact">Contact Us</a></li>
            {/* Add more navigation links */}
          </ul>
        </div>
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
