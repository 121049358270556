// src/About.js

import React from 'react';
import './about.css'; // Create this CSS file for About component styling

function About() {
  return (
    <div className="about" id='about'>
      <div className="contact-card">
        {/* Contact Information */}
        <h2>About Us</h2>
        <p>Ph.no : 8019192444</p>
        <p>Branch Office
H. No.: 1-8-32/39 , Bapu Bagh, Ram Gopalpet Police Station, Secunderabad - 500 003, Telangana, India.</p>
      </div>
      <div className="image-slides">
        {/* Image slides */}
        {/* Add your image slideshow components here */}
      </div>
    </div>
  );
}

export default About;
